import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/enviroment';
import { ITopUpDetails, ITopUpListResponse } from '../interfaces/topUp';
import { IStatus } from '../interfaces/exchanges';

@Injectable({
  providedIn: 'root',
})
export class TopUpService {
  // this array for all status of exchanges
  public status: BehaviorSubject<any> = new BehaviorSubject(null);

  public topUps: BehaviorSubject<ITopUpListResponse> = new BehaviorSubject(
    {} as ITopUpListResponse
  );
  public userToken = sessionStorage.getItem('adminUserToken');
  private _HttpClient = inject(HttpClient);

  constructor() {
    this.subscribeOnGetStatusOfTopUp();
  }

  getTopUps(page: number, limit: number, referenceId?: string, paymentMethod?: string, status?: string): Observable<ITopUpListResponse> {
    const optionalParams = [];
    if (status == 'onHold') status = 'on_hold';
    console.log(status);
    if (referenceId) optionalParams.push(`&referenceId=${referenceId}`);
    if (paymentMethod) optionalParams.push(`&paymentMethod=${paymentMethod}`);
    if (status) optionalParams.push(`&status=${status.toUpperCase()}`);

    return this._HttpClient.get<ITopUpListResponse>(`${environment.apiUrl}/v1/wallet/admin-topup-wallet-list?page=${page}&limit=${limit}${optionalParams.join('').split(',').toString()}`);
  }

  topUpDetails(id: string): Observable<ITopUpDetails> {
    return this._HttpClient.get<ITopUpDetails>(`${environment.apiUrl}/v1/wallet/admin-topup-wallet-details/${id}`);
  }
  changeStatusOfTopUp(id: string, statue: string): Observable<any> {
    if (statue == 'ON HOLD') statue = 'ON_HOLD';
    return this._HttpClient.patch(`${environment.apiUrl}/v1/wallet/change-status/${id}/${statue}`, {});
  }
  subscribeOnGetStatusOfTopUp() {
    this.status.subscribe(res => {
      console.log('res: ', res);
      if (!res) this.getStatusOfTopUp().subscribe((res) => {
        this.status.next(res);
      });
    })
  }
  getStatusOfTopUp(): Observable<IStatus> {
    return this._HttpClient.get<IStatus>(`${environment.apiUrl}/v1/wallet/admin-topup-stats`, {});
  }
}
